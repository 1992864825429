import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconRefresh = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<g clipPath="url(#a)">
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="M19.5 7.03V3H21v5.75c0 .69-.56 1.25-1.25 1.25H14V8.5h4.63a7.5 7.5 0 0 0-6.63-4 7.48 7.48 0 0 0-6.93 4.63l-1.385-.575A8.97 8.97 0 0 1 12 3c3.06 0 5.86 1.56 7.5 4.03M5.37 15.5a7.53 7.53 0 0 0 6.63 4 7.48 7.48 0 0 0 6.93-4.63l1.385.575A8.97 8.97 0 0 1 12 21a9.03 9.03 0 0 1-7.5-4.025V21H3v-5.75c0-.69.56-1.25 1.25-1.25H10v1.5z"
					clipRule="evenodd"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="white" d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>,
		elementProps
	);
};
export default LineNeoIconRefresh;
