import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconUser = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M6.5 7.5c0 3.035 2.465 5.5 5.5 5.5s5.5-2.465 5.5-5.5S15.035 2 12 2a5.503 5.503 0 0 0-5.5 5.5m1.5 0c0-2.205 1.795-4 4-4s4 1.795 4 4-1.795 4-4 4-4-1.795-4-4m10.57 10.91 1.015 3.785 1.45-.39-1.015-3.785a4.76 4.76 0 0 0-4.59-3.52H8.57a4.75 4.75 0 0 0-4.59 3.52l-1.015 3.785 1.45.39L5.43 18.41A3.25 3.25 0 0 1 8.57 16h6.86c1.47 0 2.76.99 3.14 2.41"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconUser;
