import React, { AriaAttributes, forwardRef } from 'react';
import { IconNames, NeoIcon } from '@web-apps/neo-icons';
import classnames from 'classnames';
import { useDisabled } from '../../contexts/disabledContext';
import { NeoLanguageKeys, usePandaContext } from '../../contexts/pandaContext';

import classes from './Button.module.css';

interface PropsTypeButton {
	/**
	 * Nutze Typ `submit` in einem `<form>`.
	 * Nutze Typ `button` in allen anderen Fällen.
	 */
	type?: 'button';
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface PropsTypeSubmit {
	type: 'submit';
	onClick?: undefined;
}

type PropsTypeSubmitOrButton = PropsTypeSubmit | PropsTypeButton;

type Size = 'small' | 'medium' | 'large' | 'xlarge';
type Width = 'content' | 'max' | 'max-on-touch-device';
type Variant = 'quiet' | 'normal' | 'loud';
type Action = 'trigger' | 'confirm';

export type Icons =
	| 'add'
	| 'back'
	| 'close'
	| 'copy'
	| 'delete'
	| 'edit'
	| 'export'
	| 'info'
	| 'next'
	| 'show-password'
	| 'hide-password'
	| 'up'
	| 'down'
	| 'renew-invoice';

type Props = {
	/**
	 * Verwende am Ende eines mehrstufigen Prozesses immer einen `confirm` Button.
	 * Nutze einen `trigger` Button, um eine einzelne Aktion auszuführen oder einen mehrstufigen Prozess zu starten.
	 */
	action?: Action;
	size?: Size;
	width?: Width;
	/**
	 * Die `variant` bestimmt die visuelle Lautstärke des Buttons.
	 */
	variant?: Variant;
	icon?: Icons;
	/**
	 * Setze `true` wenn dein Button kein Label benötigt.
	 */
	iconOnly?: boolean;
	/**
	 * Setze `true` wenn es sich um eine negative oder destruktive Aktion handelt.
	 */
	critical?: boolean;
	/**
	 * Setze diese Prop nur, wenn das automatische Resizing bei Touch Devices dein Interface zerstört.
	 * Kümmere dich dann darum, dass die Touchfläche groß genug ist.
	 */
	deprecated?: boolean;
	/**
	 * Der `tabIndex` sollte nur in absoluten Ausnahmefällen gesetzt werden (z.B. wenn accessibility-guidelines das so vorschreiben)
	 */
	tabIndex?: number;
	/**
	 * Der disabled State kann auch über den Context gesteuert werden.
	 * [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs)
	 */
	dataFocusId?: string;
	/**
	 * 'dataFocusId' ist ein Identifier, der dabei helfen soll, fokusierbare Elemente zu identifizieren.
	 */
	disabled?: boolean;
	/**
	 * Der loading Zustand setzt für den Button auch immer implizit `disabled=true`.
	 */
	loading?: boolean;
	/**
	 * Du kannst auf ein eigenes Label verzichten, wenn du ein `icon` übergibst, weil diese automatisch Labels mitbringen.
	 */
	children?: string;
} & PropsTypeSubmitOrButton &
	AriaAttributes;

const styles = {
	spinner: (variant: Variant, critical: boolean, isConfirmButton: boolean) =>
		classnames(
			'absolute',
			'top-0',
			'left-0',
			'margin-x-auto',
			'block',
			'flex',
			'items-center',
			'justify-center',
			'w-full',
			'h-full',
			'transition',
			!isConfirmButton &&
				classnames(
					variant === 'quiet' && !critical && 'text-neo-color-global-content-neutral-intense',
					variant === 'quiet' && critical && 'text-neo-color-global-content-critical-moderate',
					variant === 'normal' && !critical && 'text-neo-color-global-content-primary-intense',
					variant === 'normal' && critical && 'text-neo-color-global-content-critical-moderate',
					variant === 'loud' && !critical && 'text-neo-color-global-content-primary-on-intense',
					variant === 'loud' && critical && 'text-neo-color-global-content-critical-on-intense'
				),
			isConfirmButton &&
				classnames(
					variant &&
						(critical
							? 'text-neo-color-global-content-critical-on-intense'
							: 'text-neo-color-web-app-component-confirm-button-content-on-intense')
				)
		),
	button: {
		base: (loading: boolean) =>
			classnames(
				'group',
				'relative',
				!loading && 'cursor-pointer',
				!loading && 'disabled:bg-neo-color-global-background-primary-intense-disabled',
				!loading && 'disabled:cursor-not-allowed',
				!loading && 'disabled:text-neo-color-global-content-primary-disabled',
				!loading && 'disabled:ring-0',
				'duration-150',
				'ease-in-out',
				'flex',
				'flex-row',
				'focus-visible:outline-none',
				'focus:outline-none',
				'font-brand',
				'font-bold',
				'justify-center',
				'items-center',
				'rounded',
				'select-none',
				'transition',
				'text-left'
			),
		width: (width: Width, iconOnly: boolean) =>
			!iconOnly &&
			classnames(
				width === 'content' && 'w-auto',
				width === 'max' && 'w-full max-w-screen-xs',
				width === 'max-on-touch-device' && [
					'pointer-fine:w-auto',
					'pointer-coarse:w-full',
					'pointer-coarse:max-w-screen-xs',
				]
			),
		size: (
			size: Size,
			iconOnly: boolean,
			labelOnly: boolean,
			iconAndLabel: boolean,
			deprecated: boolean
		) =>
			classnames(
				!iconOnly && [
					size === 'small' && 'text-xs/16',
					size === 'medium' && 'text-sm/16',
					size === 'large' && 'text-base/24',
					size === 'xlarge' && 'text-base/24',
				],
				iconOnly && [
					size === 'small' && 'p-4',
					size === 'medium' && 'p-4',
					size === 'large' && 'p-8',
					size === 'xlarge' && 'p-12',
				],
				labelOnly && [
					size === 'small' && 'px-12 py-4',
					size === 'medium' && 'px-16 py-8',
					size === 'large' && 'px-20 py-8',
					size === 'xlarge' && 'px-24 py-12',
				],
				iconAndLabel && [
					size === 'small' && 'pl-8 pr-12 py-4 gap-6',
					size === 'medium' && 'pl-8 pr-16 py-4 gap-8',
					size === 'large' && 'pl-12 pr-20 py-8 gap-10',
					size === 'xlarge' && 'pl-16 pr-24 py-12 gap-12',
					!deprecated && 'pointer-coarse:pl-16',
				],
				!deprecated &&
					!iconOnly && [
						'pointer-coarse:gap-12',
						'pointer-coarse:px-24',
						'pointer-coarse:py-12',
						'pointer-coarse:text-base/24',
					],
				!deprecated && iconOnly && ['pointer-coarse:p-12']
			),
		variant: (loading: boolean, variant: Variant, critical: boolean, isConfirmButton: boolean) =>
			!isConfirmButton &&
			classnames(
				variant === 'quiet' &&
					!critical && [
						'bg-neo-color-global-background-static-transparent',
						!loading && 'text-neo-color-global-content-neutral-intense',
						!loading && 'hover:bg-neo-color-global-background-primary-soft-hover',
						!loading && 'hover:text-neo-color-global-content-primary-intense',
						'focus-visible:ring-focus-inset',
						!loading && 'active:bg-neo-color-global-background-primary-soft-active',
						!loading && 'active:text-neo-color-global-content-primary-intense',
					],
				variant === 'quiet' &&
					critical && [
						'bg-neo-color-global-background-static-transparent',
						!loading && 'text-neo-color-global-content-critical-moderate',
						!loading && 'hover:bg-neo-color-global-background-critical-soft-hover',
						!loading && 'hover:text-neo-color-global-content-critical-moderate',
						'focus-visible:ring-focus-inset',
						!loading && 'active:bg-neo-color-global-background-critical-soft-active',
						!loading && 'active:text-neo-color-global-content-critical-moderate',
					],
				variant === 'normal' &&
					!critical && [
						'bg-neo-color-global-background-primary-soft-default',
						!loading && 'text-neo-color-global-content-primary-intense',
						!loading && 'hover:bg-neo-color-global-background-primary-soft-hover',
						!loading && 'hover:text-neo-color-global-content-primary-intense',
						'focus-visible:ring-focus-inset',
						!loading && 'active:bg-neo-color-global-background-primary-soft-active',
						!loading && 'active:text-neo-color-global-content-primary-intense',
					],

				variant === 'normal' &&
					critical && [
						'bg-neo-color-global-background-critical-soft-default',
						!loading && 'text-neo-color-global-content-critical-moderate',
						!loading && 'hover:bg-neo-color-global-background-critical-soft-hover',
						!loading && 'hover:text-neo-color-global-content-critical-moderate',
						'focus-visible:ring-focus-inset',
						!loading && 'active:bg-neo-color-global-background-critical-soft-active',
						!loading && 'active:text-neo-color-global-content-critical-moderate',
					],
				variant === 'loud' && [
					'bg-neo-color-global-background-primary-intense-default',
					!loading && 'text-neo-color-global-content-primary-on-intense',
					!loading && 'hover:bg-neo-color-global-background-primary-intense-hover',
					'focus-visible:ring-focus-inset',
					!loading && 'active:bg-neo-color-global-background-primary-intense-active',
				]
			),
		confirm: (loading: boolean, isConfirmButton: boolean, critical: boolean) =>
			isConfirmButton &&
			classnames(
				critical
					? [
							'bg-neo-color-global-background-critical-intense-default',
							!loading && 'text-neo-color-global-content-critical-on-intense',
							!loading && 'hover:bg-neo-color-global-background-critical-intense-hover',
							'focus-visible:ring-focus-inset',
							!loading && 'active:bg-neo-color-global-background-critical-intense-active',
						]
					: [
							'bg-neo-color-web-app-component-confirm-button-background-default',
							!loading && 'text-neo-color-web-app-component-confirm-button-content-on-intense',
							!loading && 'hover:bg-neo-color-web-app-component-confirm-button-background-hover',
							!loading &&
								'hover:text-neo-color-web-app-component-confirm-button-content-on-intense',
							'focus-visible:ring-focus-inset',
							!loading && 'active:bg-neo-color-web-app-component-confirm-button-background-active',
						]
			),
		spinner: (loading: boolean) =>
			loading &&
			classnames('text-transparent', 'hover:text-transparent', 'active:text-transparent'),
	},
};

export const iconMapping: Record<
	string,
	{ languageKey: keyof NeoLanguageKeys; NeoIcon: IconNames }
> = {
	add: {
		languageKey: 'PANDA_ICON_ADD',
		NeoIcon: 'Add',
	},
	back: {
		languageKey: 'PANDA_ICON_BACK',
		NeoIcon: 'Back',
	},
	close: {
		languageKey: 'PANDA_ICON_CLOSE',
		NeoIcon: 'Close',
	},
	copy: {
		languageKey: 'PANDA_ICON_COPY',
		NeoIcon: 'Copy',
	},
	delete: {
		languageKey: 'PANDA_ICON_DELETE',
		NeoIcon: 'Delete',
	},
	edit: {
		languageKey: 'PANDA_ICON_EDIT',
		NeoIcon: 'Edit',
	},
	export: {
		languageKey: 'PANDA_ICON_EXPORT',
		NeoIcon: 'Download',
	},
	info: {
		languageKey: 'PANDA_ICON_INFO',
		NeoIcon: 'Info',
	},
	next: {
		languageKey: 'PANDA_ICON_NEXT',
		NeoIcon: 'Next',
	},
	'show-password': {
		languageKey: 'PANDA_ICON_SHOW_PASSWORD',
		NeoIcon: 'Show',
	},
	'hide-password': {
		languageKey: 'PANDA_ICON_HIDE_PASSWORD',
		NeoIcon: 'Hide',
	},
	up: {
		languageKey: 'PANDA_ICON_UP',
		NeoIcon: 'Up',
	},
	down: {
		languageKey: 'PANDA_ICON_DOWN',
		NeoIcon: 'Down',
	},
	'renew-invoice': {
		languageKey: 'PANDA_ICON_RENEW_INVOICE',
		NeoIcon: 'Refresh',
	},
} as const;

export const getNeoIconLabel = (lks: NeoLanguageKeys, icon: keyof typeof iconMapping) =>
	lks[iconMapping[icon].languageKey] as keyof typeof iconMapping;

const Button = forwardRef<HTMLButtonElement, Props>(
	(
		{
			type = 'button',
			action = 'trigger',
			size = 'medium',
			width = 'content',
			variant = 'normal',
			icon,
			iconOnly = false,
			critical = false,
			tabIndex,
			dataFocusId,
			deprecated = false,
			disabled,
			children,
			loading = false,
			...ariaAttributes
		},
		ref
	) => {
		const isDisabled = useDisabled(disabled);
		const { languageKeys } = usePandaContext();

		const label = icon && !children ? getNeoIconLabel(languageKeys, icon) : children;

		const getIconSize = () => {
			switch (size) {
				case 'small':
					return 16;
				case 'medium':
				case 'large':
				case 'xlarge':
					return 24;
			}
		};

		return (
			<button
				tabIndex={tabIndex}
				data-focus-id={dataFocusId}
				className={classnames(
					styles.button.base(loading),
					styles.button.size(size, iconOnly, !icon, !!icon && !iconOnly, deprecated),
					styles.button.width(width, iconOnly),
					styles.button.variant(loading, variant, critical, action === 'confirm'),
					styles.button.confirm(loading, action === 'confirm', critical),
					styles.button.spinner(loading)
				)}
				// eslint-disable-next-line react/button-has-type
				type={type}
				disabled={isDisabled || loading}
				aria-label={label}
				title={iconOnly ? label : undefined}
				ref={ref}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...ariaAttributes}
			>
				{icon && <NeoIcon name={iconMapping[icon].NeoIcon} variant="line" size={getIconSize()} />}
				{!iconOnly && label}

				{loading && (
					<div
						className={styles.spinner(variant, critical, action === 'confirm')}
						role="presentation"
					>
						<svg
							// Hack to ensure the spinner is not shown in a broken state before the css is loaded
							style={{ display: 'none' }}
							className={classes.spinner}
							viewBox="0 0 16 16"
							data-testid="spinner"
						>
							<circle className={classes.path} cx="8" cy="8" r="7" />
						</svg>
					</div>
				)}
			</button>
		);
	}
);

export { Button };
