import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconHide = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m22.08 11.98-.145.33c-.08.18-1.28 2.815-3.72 4.845l3.065 3.065-1.06 1.06-17.5-17.5 1.06-1.06 3.235 3.235C8.38 5.1 10.035 4.5 12 4.5c6.135 0 9.765 6.87 9.915 7.16zM10.85 9.79l3.36 3.36c.18-.345.29-.735.29-1.15A2.5 2.5 0 0 0 12 9.5c-.42 0-.805.11-1.15.29M16 12c0 .835-.26 1.605-.695 2.245l1.82 1.82c1.785-1.45 2.88-3.3 3.27-4.045-.765-1.295-3.87-6.025-8.4-6.025-1.485 0-2.77.435-3.87 1.07l1.63 1.63A4 4 0 0 1 12 8c2.205 0 4 1.795 4 4"
				clipRule="evenodd"
			/>
			<path
				fill="currentColor"
				d="M3.585 12.005C4.245 13.265 7.055 18 12 18c.87 0 1.675-.155 2.415-.405l1.175 1.175c-1.06.45-2.25.73-3.59.73-6.66 0-9.805-6.895-9.935-7.19l-.135-.305.135-.305.005-.01c.097-.209.951-2.048 2.625-3.815L5.77 8.95a14.1 14.1 0 0 0-2.185 3.055"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconHide;
