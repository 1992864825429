import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconCalendar = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M18.25 3h-.75V2H16v1H8V2H6.5v1h-.75A2.755 2.755 0 0 0 3 5.75v12.5A2.755 2.755 0 0 0 5.75 21h12.5A2.755 2.755 0 0 0 21 18.25V5.75A2.755 2.755 0 0 0 18.25 3M5.75 4.5h.75V6H8V4.5h8V6h1.5V4.5h.75c.69 0 1.25.56 1.25 1.25V7.5h-15V5.75c0-.69.56-1.25 1.25-1.25m12.5 15H5.75c-.69 0-1.25-.56-1.25-1.25V9h15v9.25c0 .69-.56 1.25-1.25 1.25M7 10.995v2h2v-2zm4 0h2v2h-2zm4 0v2h2v-2zm-8 6v-2h2v2zm6-2h-2v2h2zm2 2v-2h2v2z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconCalendar;
