import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconShow = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M8 12c0 2.205 1.795 4 4 4s4-1.795 4-4-1.795-4-4-4-4 1.795-4 4m1.5 0a2.501 2.501 0 0 1 5 0 2.5 2.5 0 0 1-5 0"
				clipRule="evenodd"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M2.065 12.31c.13.29 3.275 7.19 9.935 7.19s9.805-6.895 9.935-7.19l.145-.33-.165-.32c-.15-.295-3.78-7.16-9.915-7.16-6.66 0-9.805 6.905-9.935 7.2l-.135.305zM12 18c-4.95 0-7.755-4.735-8.415-5.995C4.245 10.74 7.055 6 12 6c4.525 0 7.635 4.73 8.4 6.025C19.72 13.32 16.95 18 12 18"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconShow;
