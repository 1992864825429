import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconAdd = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path fill="currentColor" d="M20 11.25h-7.25V4h-1.5v7.25H4v1.5h7.25V20h1.5v-7.25H20z" />
		</svg>,
		elementProps
	);
};
export default LineNeoIconAdd;
