import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconCall_queue = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				d="M17 7c-1.835 0-3.085.96-4.075 2.21q.449.675.845 1.365C14.625 9.36 15.57 8.5 17 8.5c1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5c-2.07 0-3.13-1.8-4.355-3.88C11.37 9.455 9.93 7 7 7c-2.755 0-5 2.245-5 5s2.245 5 5 5c1.835 0 3.085-.96 4.075-2.21q-.449-.675-.845-1.365C9.38 14.64 8.43 15.5 7 15.5c-1.93 0-3.5-1.57-3.5-3.5S5.07 8.5 7 8.5c2.07 0 3.13 1.8 4.355 3.88C12.63 14.545 14.07 17 17 17c2.755 0 5-2.245 5-5s-2.245-5-5-5"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconCall_queue;
