import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconDownload = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12.885 16.675a1.25 1.25 0 0 1-.885.365c-.32 0-.64-.12-.885-.365L6.47 12.03l1.06-1.06 3.72 3.72V3h1.5v11.69l3.72-3.72 1.06 1.06zM19.5 18.25V15.5H21v2.75A2.755 2.755 0 0 1 18.25 21H5.75A2.755 2.755 0 0 1 3 18.25V15.5h1.5v2.75c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconDownload;
