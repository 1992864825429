import React from 'react';
import type { DuotoneIconBaseProps, IconBaseProps } from '../types';
import type { IconNames, IconVariants } from './iconNames';

import LineNeoIconAdd from './line/LineNeoIconAdd';
import LineNeoIconAnnouncement from './line/LineNeoIconAnnouncement';
import LineNeoIconBack from './line/LineNeoIconBack';
import LineNeoIconCalendar from './line/LineNeoIconCalendar';
import LineNeoIconCall_queue from './line/LineNeoIconCall_queue';
import LineNeoIconClose from './line/LineNeoIconClose';
import LineNeoIconCopy from './line/LineNeoIconCopy';
import LineNeoIconDelete from './line/LineNeoIconDelete';
import LineNeoIconDown from './line/LineNeoIconDown';
import LineNeoIconDownload from './line/LineNeoIconDownload';
import LineNeoIconEdit from './line/LineNeoIconEdit';
import LineNeoIconGreeting from './line/LineNeoIconGreeting';
import LineNeoIconHide from './line/LineNeoIconHide';
import LineNeoIconIncomig_forwarding from './line/LineNeoIconIncomig_forwarding';
import LineNeoIconInfo from './line/LineNeoIconInfo';
import LineNeoIconIvr from './line/LineNeoIconIvr';
import LineNeoIconMulti_user_channel from './line/LineNeoIconMulti_user_channel';
import LineNeoIconNext from './line/LineNeoIconNext';
import LineNeoIconNumber from './line/LineNeoIconNumber';
import LineNeoIconNumber_extension from './line/LineNeoIconNumber_extension';
import LineNeoIconOutgoing_forwarding from './line/LineNeoIconOutgoing_forwarding';
import LineNeoIconRefresh from './line/LineNeoIconRefresh';
import LineNeoIconSettings from './line/LineNeoIconSettings';
import LineNeoIconSheep from './line/LineNeoIconSheep';
import LineNeoIconShow from './line/LineNeoIconShow';
import LineNeoIconTimetable from './line/LineNeoIconTimetable';
import LineNeoIconUp from './line/LineNeoIconUp';
import LineNeoIconUser from './line/LineNeoIconUser';
import LineNeoIconVoicemail from './line/LineNeoIconVoicemail';
import SolidNeoIconSheep from './solid/SolidNeoIconSheep';
import DuotoneNeoIconAnnouncement from './duotone/DuotoneNeoIconAnnouncement';
import DuotoneNeoIconCalendar from './duotone/DuotoneNeoIconCalendar';
import DuotoneNeoIconCall_queue from './duotone/DuotoneNeoIconCall_queue';
import DuotoneNeoIconGreeting from './duotone/DuotoneNeoIconGreeting';
import DuotoneNeoIconIncomig_forwarding from './duotone/DuotoneNeoIconIncomig_forwarding';
import DuotoneNeoIconIvr from './duotone/DuotoneNeoIconIvr';
import DuotoneNeoIconMulti_user_channel from './duotone/DuotoneNeoIconMulti_user_channel';
import DuotoneNeoIconNumber from './duotone/DuotoneNeoIconNumber';
import DuotoneNeoIconNumber_extension from './duotone/DuotoneNeoIconNumber_extension';
import DuotoneNeoIconOutgoing_forwarding from './duotone/DuotoneNeoIconOutgoing_forwarding';
import DuotoneNeoIconSheep from './duotone/DuotoneNeoIconSheep';
import DuotoneNeoIconTimetable from './duotone/DuotoneNeoIconTimetable';
import DuotoneNeoIconUser from './duotone/DuotoneNeoIconUser';
import DuotoneNeoIconVoicemail from './duotone/DuotoneNeoIconVoicemail';

const iconComponents = {
	'line.Add': LineNeoIconAdd,
	'line.Announcement': LineNeoIconAnnouncement,
	'line.Back': LineNeoIconBack,
	'line.Calendar': LineNeoIconCalendar,
	'line.Call_queue': LineNeoIconCall_queue,
	'line.Close': LineNeoIconClose,
	'line.Copy': LineNeoIconCopy,
	'line.Delete': LineNeoIconDelete,
	'line.Down': LineNeoIconDown,
	'line.Download': LineNeoIconDownload,
	'line.Edit': LineNeoIconEdit,
	'line.Greeting': LineNeoIconGreeting,
	'line.Hide': LineNeoIconHide,
	'line.Incomig_forwarding': LineNeoIconIncomig_forwarding,
	'line.Info': LineNeoIconInfo,
	'line.Ivr': LineNeoIconIvr,
	'line.Multi_user_channel': LineNeoIconMulti_user_channel,
	'line.Next': LineNeoIconNext,
	'line.Number': LineNeoIconNumber,
	'line.Number_extension': LineNeoIconNumber_extension,
	'line.Outgoing_forwarding': LineNeoIconOutgoing_forwarding,
	'line.Refresh': LineNeoIconRefresh,
	'line.Settings': LineNeoIconSettings,
	'line.Sheep': LineNeoIconSheep,
	'line.Show': LineNeoIconShow,
	'line.Timetable': LineNeoIconTimetable,
	'line.Up': LineNeoIconUp,
	'line.User': LineNeoIconUser,
	'line.Voicemail': LineNeoIconVoicemail,
	'solid.Sheep': SolidNeoIconSheep,
	'duotone.Announcement': DuotoneNeoIconAnnouncement,
	'duotone.Calendar': DuotoneNeoIconCalendar,
	'duotone.Call_queue': DuotoneNeoIconCall_queue,
	'duotone.Greeting': DuotoneNeoIconGreeting,
	'duotone.Incomig_forwarding': DuotoneNeoIconIncomig_forwarding,
	'duotone.Ivr': DuotoneNeoIconIvr,
	'duotone.Multi_user_channel': DuotoneNeoIconMulti_user_channel,
	'duotone.Number': DuotoneNeoIconNumber,
	'duotone.Number_extension': DuotoneNeoIconNumber_extension,
	'duotone.Outgoing_forwarding': DuotoneNeoIconOutgoing_forwarding,
	'duotone.Sheep': DuotoneNeoIconSheep,
	'duotone.Timetable': DuotoneNeoIconTimetable,
	'duotone.User': DuotoneNeoIconUser,
	'duotone.Voicemail': DuotoneNeoIconVoicemail} as const;

type IconComponentsType = typeof iconComponents;

// Full props including variant and name
type IconProps<N extends IconNames> = {
	name: N;
	variant: IconVariants<N>;
} & ({ variant: 'duotone' } extends { variant: IconVariants<N> } // If the variant is 'duotone', include DuotoneIconBaseProps, otherwise IconBaseProps
	?
			| ({ variant: 'duotone' } & DuotoneIconBaseProps)
			| ({ variant: Exclude<IconVariants<N>, 'duotone'> } & IconBaseProps)
	: IconBaseProps);

export function NeoIcon<N extends IconNames>({
	name,
	variant,
	size = 24,
	...props
}: IconProps<N>): React.ReactElement | null {
	const key = `${variant}.${name}`;
	const Icon = iconComponents[key as keyof IconComponentsType];
	if (!Icon) return null;

	if (variant === 'duotone') {
		// Since TypeScript can't narrow the type based on the runtime value of variant,
		// we need to handle the case where color2 might be missing
		const propsWithColor2 = { color2: '', ...props };
		const { color2, ...restProps } = propsWithColor2;
		return <Icon size={size} color2={color2} {...restProps} />;
	}
	return <Icon size={size} color2='' {...props} />;
}
