import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconNumber = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				d="M21 9V7.5h-3.98l.965-4.335-1.465-.325-1.035 4.665H10.52l.965-4.335-1.465-.325L8.985 7.51H3v1.5h5.65l-1.335 6H3v1.5h3.98l-.965 4.335 1.465.325 1.035-4.665h4.965l-.965 4.335 1.465.325 1.035-4.665h5.98V15h-5.65l1.335-6H21m-7.185 6H8.85l1.335-6h4.965z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconNumber;
