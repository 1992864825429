import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconEdit = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M9.995 21.8c.655.13 1.33.2 2.005.2v-.005c.67 0 1.35-.065 2.005-.2.58-.115.995-.63.995-1.225v-1.155a7.8 7.8 0 0 0 1.925-1.11l1 .575c.515.295 1.17.195 1.555-.245a10 10 0 0 0 2.01-3.48 1.25 1.25 0 0 0-.565-1.47l-1-.575a8.2 8.2 0 0 0 0-2.22l1-.58c.51-.295.75-.915.565-1.47a10 10 0 0 0-2.01-3.48c-.39-.44-1.04-.54-1.555-.245l-1 .575a8 8 0 0 0-1.92-1.11V3.425c0-.59-.42-1.11-.995-1.225-1.31-.27-2.7-.27-4.015 0-.58.115-.995.63-.995 1.225V4.58a7.8 7.8 0 0 0-1.925 1.11l-1-.575a1.25 1.25 0 0 0-1.555.245 10 10 0 0 0-2.01 3.48c-.185.555.05 1.175.565 1.47l1 .58a8.2 8.2 0 0 0 0 2.22l-1 .58c-.51.295-.75.915-.565 1.47a9.9 9.9 0 0 0 2.01 3.48c.39.44 1.04.54 1.555.245l1-.575c.59.46 1.235.83 1.925 1.11v1.155c0 .59.42 1.11.995 1.225m3.505-1.43a8.6 8.6 0 0 1-3 0v-2.02l-.52-.17a6.5 6.5 0 0 1-2.325-1.345l-.405-.365-1.75 1.01A8.4 8.4 0 0 1 4 14.885l1.75-1.01-.11-.53Q5.499 12.684 5.5 12c0-.455.05-.905.14-1.345l.11-.53L4 9.115A8.4 8.4 0 0 1 5.5 6.52l1.75 1.01.405-.365A6.4 6.4 0 0 1 9.98 5.82l.52-.17V3.63a8.6 8.6 0 0 1 3 0v2.015l.52.17c.865.28 1.645.735 2.325 1.345l.405.365 1.75-1.01A8.4 8.4 0 0 1 20 9.11l-1.75 1.01.11.53q.141.661.14 1.345c0 .455-.05.905-.14 1.345l-.11.53L20 14.88a8.4 8.4 0 0 1-1.5 2.6l-1.75-1.01-.405.365a6.4 6.4 0 0 1-2.325 1.345l-.52.17zM8 12c0 2.205 1.795 4 4 4s4-1.795 4-4-1.795-4-4-4-4 1.795-4 4m1.5 0a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1-5 0"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconEdit;
