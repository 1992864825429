import * as React from 'react';
import type { DuotoneIconBaseProps } from '../../types';
const DuotoneNeoIconVoicemail = ({
	size = 24,
	...props
}: DuotoneIconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill={props.color2}
				fillRule="evenodd"
				d="M6.25 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6m11.5 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
				clipRule="evenodd"
			/>
			<path
				fill="currentColor"
				d="M17.75 7.75A4.253 4.253 0 0 0 13.5 12c0 1.05.385 2.01 1.015 2.75h-5.03c.63-.74 1.015-1.7 1.015-2.75a4.253 4.253 0 0 0-4.25-4.25A4.253 4.253 0 0 0 2 12a4.253 4.253 0 0 0 4.25 4.25h11.5A4.253 4.253 0 0 0 22 12a4.253 4.253 0 0 0-4.25-4.25M3.5 12a2.755 2.755 0 0 1 2.75-2.75A2.755 2.755 0 0 1 9 12a2.755 2.755 0 0 1-2.75 2.75A2.755 2.755 0 0 1 3.5 12m14.25 2.75A2.755 2.755 0 0 1 15 12a2.755 2.755 0 0 1 2.75-2.75A2.755 2.755 0 0 1 20.5 12a2.755 2.755 0 0 1-2.75 2.75"
			/>
		</svg>,
		elementProps
	);
};
export default DuotoneNeoIconVoicemail;
