import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconAnnouncement = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				d="M20.365 2.545a1.26 1.26 0 0 0-1.26.015L12.54 6.5H5.75A2.755 2.755 0 0 0 3 9.25v3.5a2.755 2.755 0 0 0 2.75 2.75h.815l1.61 6h3.435c.39 0 .755-.18.99-.49.24-.31.315-.705.215-1.085L11.63 15.5h.91l6.565 3.94c.195.12.42.18.645.18s.425-.055.615-.16c.39-.22.635-.64.635-1.09V3.635c0-.45-.245-.865-.635-1.09M11.285 20h-1.96L8.12 15.5h1.955L11.28 20zm8.215-2.075L12.96 14H5.75c-.69 0-1.25-.56-1.25-1.25v-3.5C4.5 8.56 5.06 8 5.75 8h7.21l6.54-3.925z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconAnnouncement;
