import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconInfo = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 22C6.485 22 2 17.515 2 12S6.485 2 12 2s10 4.485 10 10-4.485 10-10 10m0-18.5c-4.685 0-8.5 3.815-8.5 8.5s3.815 8.5 8.5 8.5 8.5-3.815 8.5-8.5-3.815-8.5-8.5-8.5m.75 12.5v-5.5h-1.5V16zM13 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconInfo;
