import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconGreeting = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const elementProps = {
		width: size === 'inline' ? '1em' : size,
		height: size === 'inline' ? '1em' : size,
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M10.86 6.164a2.75 2.75 0 0 1 2.79-.794l4.923 1.405a1.25 1.25 0 0 1 1.25-1.275H23V7h-2.891l1.295 9.5H23V18h-1.814a1.25 1.25 0 0 1-1.225-1H17.01a2.74 2.74 0 0 1-1.531 1.894l-2.42 1.117a4.75 4.75 0 0 1-4.56-.318l-4.435-2.852-.01.078A1.25 1.25 0 0 1 2.812 18H1v-1.5h1.595L3.891 7H1V5.5h3.177c.612 0 1.11.436 1.226 1h5.152zm6.066 9.336h2.828l-.969-7.104-5.546-1.584a1.25 1.25 0 0 0-1.268.361l-3.675 4.042c.408.303.966.331 1.407.056l3.278-2.05 3.658 5.69q.181.285.287.589M5.268 8l-.982 7.201 5.024 3.23a3.25 3.25 0 0 0 3.12.218l2.42-1.117a1.25 1.25 0 0 0 .527-1.81l-2.857-4.443-2.022 1.264a2.75 2.75 0 0 1-3.402-.388l-.881-.88L9.192 8z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconGreeting;
